<template>
  <v-menu
    ref="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    nudge-top="20"
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :append-icon="appendIcon"
        :class="computedClasses"
        readonly
        :disabled="disabled"
        :hint="hint"
        :persistent-hint="persistentHint"
        :clearable="!disabled && clearable"
        :label="label"
        :value="dateDisplay"
        :hide-details="hideDetails"
        v-bind="$attrs"
        v-on="on"
        @click:clear="clearDate"
      />
    </template>
    <v-date-picker
      v-model="date"
      no-title
      :max="max"
      :min="min"
      @change="$refs.dateMenu.save(date)"
    />
  </v-menu>
</template>
<style lang="scss">
.rai-date-picker {
  &.rai-date-picker--shrink {
    flex: 0 1 12ch;
  }
}
</style>
<script>
import { format, parseISO } from "date-fns";
export default {
  name: "RaiDatePicker",
  inheritAttrs: false,
  props: {
    // The date input value
    value: {
      type: [String, Date],
      default: null,
    },
    // A string compatible with the date-fns format API
    // ex: "MM/dd/yyyy", "MMM d, yyyy", "M/d/yy", etc
    formatString: {
      type: String,
      default: "MMM d, yyyy",
    },
    appendIcon: {
      type: [String, Object],
      default: null,
    },
    // The label for the text-field
    label: {
      type: String,
      default: null,
    },
    // The maximum date allowed in the format of "yyyy-MM-dd"
    max: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    shrink: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: this.value,
    };
  },
  computed: {
    dateDisplay() {
      return this.date ? format(parseISO(this.date), this.formatString) : "";
    },
    computedClasses() {
      return {
        "rai-date-picker": true,
        "rai-date-picker--shrink": this.shrink,
      };
    },
  },
  watch: {
    // This ensures that when the VALUE passed changes,
    // the change is reflected in the picker
    value(val, old) {
      this.date = val;

      // I don't know why this was here.
      // if (val === null) {
      //   this.date = val
      // }
    },
    date(val, old) {
      this.$emit("input", val);
    },
  },
  methods: {
    clearDate() {
      this.date = null;
    },
  },
};
</script>
